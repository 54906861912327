<template>
  <div>
    <div id="model-code-page">
      <h1 class="h1-title">My Market</h1>
      <div>
        <div class="margin-top">
          <h4 class="h4">{{ modelCode }}</h4>
          <h5 class="h5">{{ getYearString() }}</h5>
        </div>
      </div>
      <div class="flex-row margin-top">
        <span class="flex-grow text-center">
          <b class="body-2-bold">Lowest Kms </b>
          <p class="body-2">{{ calculateStats().lowestMileage }}</p>
        </span>
        <span class="flex-grow text-center">
          <b class="body-2-bold">Highest Kms </b>
          <p class="body-2">{{ calculateStats().highestMileage }}</p>
        </span>
        <span class="flex-grow text-center">
          <b class="body-2-bold">Average Kms </b>
          <p class="body-2">{{ calculateStats().avgMileage }}</p>
        </span>
      </div>
      <div class="flex-row margin-top">
        <span class="flex-grow text-center">
          <b class="body-2-bold">Highest Final Offer </b>
          <p class="body-2">{{ calculateStats().highestFinal }}</p>
        </span>
        <span class="flex-grow text-center">
          <b class="body-2-bold">Lowest Final Offer </b>
          <p class="body-2">{{ calculateStats().lowestFinal }}</p>
        </span>
        <span class="flex-grow text-center">
          <b class="body-2-bold">Average Final Offer </b>
          <p class="body-2">{{ calculateStats().avgFinal }}</p>
        </span>
      </div>
      <div class="flex-row margin-right margin-bottom-05">
        <div class="margin-left-auto">
          <p class="text-field-title margin-bottom-025">Year Search</p>
          <select v-model="isForSimilarYears" class="dropdown-field">
            <option :value="true">Similar Years</option>
            <option :value="false">Exact Year</option>
          </select>
        </div>
      </div>
      <results-table availableSearchMode="my_market" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { endpoints } from "../../../api/axiosHandler.js";

import ResultsTable from "../../../components/Views/v2/ResultsTable.vue";

export default {
  components: { ResultsTable },
  name: "ModelCode",
  data: function () {
    return {
      isForSimilarYears: false
    };
  },
  methods: {
    getUrl: function () {
      if (this.isForSimilarYears) {
        return (
          endpoints.form +
          "?car_model_code=" +
          this.$route.params.mc +
          "&car_model_year_lt=" +
          (this.$route.params.year + 1) +
          "&car_model_year_gt=" +
          (this.$route.params.year - 1)
        );
      } else {
        return (
          endpoints.form +
          "?car_model_code=" +
          this.$route.params.mc +
          "&car_model_year_lt=" +
          this.$route.params.year +
          "&car_model_year_gt=" +
          this.$route.params.year
        );
      }
    },
    getYearString: function () {
      if (this.isForSimilarYears) {
        let previousYear = Number(this.$route.params.year) - 1;
        let nextYear = Number(this.$route.params.year) + 1;
        return previousYear + " - " + nextYear;
      } else {
        return this.$route.params.year;
      }
    },
    calculateStats: function () {
      var avgMileage = 0;
      var avgMileageCount = 0;
      var highestMileage = 0;
      var lowestMileage = Number.MAX_VALUE;
      var avgFinal = 0;
      var avgFinalCount = 0;
      var highestFinal = 0;
      var lowestFinal = Number.MAX_VALUE;

      this.forms().forEach(form => {
        if (form.car_mileage) {
          avgMileage += form.car_mileage;
          avgMileageCount += 1;
          if (form.car_mileage > highestMileage) {
            highestMileage = form.car_mileage;
          }
          if (form.car_mileage < lowestMileage) {
            lowestMileage = form.car_mileage;
          }
        }
        if (form.final_offer) {
          let value = form.final_offer.adjusted_value ? form.final_offer.adjusted_value : form.final_offer.price;
          if (value) {
            avgFinal += value;
            avgFinalCount += 1;
            if (value > highestFinal) {
              highestFinal = value;
            }
            if (value < lowestFinal) {
              lowestFinal = value;
            }
          }
        }
      });

      return {
        avgMileage: avgMileageCount ? (avgMileage / avgMileageCount).toFixed(0) + "km" : "N/A",
        highestMileage: avgMileageCount ? highestMileage + "km" : "N/A",
        lowestMileage: avgMileageCount ? lowestMileage + "km" : "N/A",
        avgFinal: avgFinalCount ? "$" + (avgFinal / avgFinalCount).toFixed(0) : "N/A",
        highestFinal: avgFinalCount ? "$" + highestFinal : "N/A",
        lowestFinal: avgFinalCount ? "$" + lowestFinal : "N/A"
      };
    },
    ...mapActions({
      getForms: "formStore/getForms",
      setFormsUrl: "formStore/setCurrentUrl",
      removeAllFilters: "formStore/removeAllFilters"
    })
  },
  mounted: function () {
    this.removeAllFilters();
    this.setFormsUrl(this.getUrl());
    this.getForms({});
  },
  computed: {
    modelCode: function () {
      return this.$route.params.mc;
    },
    ...mapGetters({
      forms: "formStore/getForms"
    })
  },
  watch: {
    isForSimilarYears: function () {
      this.setFormsUrl(this.getUrl());
      this.getForms({});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#model-code-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}
</style>
